import React, { useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';


import './styles.scss';

const Contact = () => {

  const [name, setName] = useState('');
  const [firstname, setFirstname] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => emailjs.init(process.env.REACT_APP_EMAILJS_PUBLIC_KEY), []);


  const handleSubmit = async (evt) => {
    evt.preventDefault();

    // control if all fields are filled
    if (!name.trim() || !firstname.trim() || !email.trim() || !message.trim()) {
      Swal.fire({
        title: 'Erreur',
        text: 'Veuillez remplir tous les champs avant de soumettre.',
        icon: 'warning',
        confirmButtonText: 'Ok',
      });
      return; 
    }

    // Vérification de la validité de l'adresse e-mail
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      Swal.fire({
        title: 'Erreur',
        text: 'Veuillez entrer une adresse e-mail valide.',
        icon: 'warning',
        confirmButtonText: 'Ok',
      });
      return; 
  }

    const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const templateId = process.env.REACT_APP_EMAILJS_CONTACT_TEMPLATE_ID;
    
    try {
      await emailjs.send(serviceId, templateId, {
        firstname: firstname,
        name: name,
        message: message,
        reply_to: email,
      });
    } catch (error) {
      Swal.fire({
        title: 'Oups !',
        text: 'Une erreur est survenue, veuillez réessayer plus tard.',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
    } finally {
      Swal.fire({
        title: 'Message envoyé !',
        text: 'Merci pour votre message, je vous répondrai dans les plus brefs délais.',
        icon: 'success',
        confirmButtonText: 'Ok',
      });
      setName('');
      setFirstname('');
      setEmail('');
      setMessage('');
    }
  };

  return (
    <div className="contact">
      <p className='contact__intro'> Une question ? Une prise de rendez-vous ? N'hésitez pas !</p>
      <div className="contact__content">
        <form autoComplete="off" method="POST" className="contact__content__form" onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            placeholder="Nom"
            onChange={(evt) => setName(evt.target.value)}
            value={name}
            className="contact__content__form__input"
          />
          <input
            type="text"
            name="firstname"
            placeholder="Prénom"
            onChange={(evt) => setFirstname(evt.target.value)}
            value={firstname}
            className="contact__content__form__input"
          />
          <input
            type="text"
            name="reply_to"
            placeholder="Email"
            onChange={(evt) => setEmail(evt.target.value)}
            value={email}
            className="contact__content__form__input"
          />
          <textarea
            name="message"
            placeholder="Message"
            onChange={(evt) => setMessage(evt.target.value)}
            value={message}
            className="contact__content__form__textarea"
          />
          <button type="submit" className="myButton contact__content__form__button">
            Envoyer
          </button>
        </form>
        <div className="contact__content__right">
          <p className="contact__content__right__name">Laura Huisman</p>
          <p className="contact__content__right__address">Le Chai</p>
          <p className="contact__content__right__address">1 Avenue du stade</p>
          <p className="contact__content__right__address">30350 CARDET</p>
          <p className="contact__content__right__tel">07.88.35.79.86</p>
          <a className="contact__content__right__email" href="mailto:lauhuis@gmail.com" >lauhuis@gmail.com</a>
          <p className="contact__content__right__siret">Siret : 82402542300016</p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
