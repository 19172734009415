import React from 'react';
import { useLocation } from 'react-router-dom';
import './styles.scss';
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

import logo from './../../assets/images/logo_white.png';


export const NavBar = ({
  isLogged,
  logout,
  user,
}) => {
    let location = useLocation(); 
    let navigate = useNavigate();

    const handleFetchItsMePage = (evt) => {
      evt.preventDefault();
      setTimeout(() => {
        navigate('/votre-photographe');
      }, 300);
    }

    const handleLogout = (evt) => {
      evt.preventDefault();
      logout();
    }


  return (
      <Navbar expand="lg" fixed='top' >
        <Container>
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Nav className="navbar mx-auto navbar__nav">
            <Nav.Link className={location.pathname === "/" ? "navbar__link active navbar__link__home" : "navbar__link navbar__link__home"} href="/">Home</Nav.Link>
              <Nav.Link className={location.pathname === "/infos" ? "navbar__link active" : "navbar__link"} href="/infos">Une séance avec moi</Nav.Link>
              <Nav.Link className={location.pathname === "/its_me" ? "navbar__link active" : "navbar__link"} onClick={handleFetchItsMePage} href="/votre-photographe">Votre photographe</Nav.Link>
              <Nav.Link href="/" className='navbar__link__logo'> <img src={logo} alt="logo" className='navbar__logo'/> </Nav.Link>
              <Nav.Link className={location.pathname === "/contact" ? "navbar__link active" : "navbar__link"} href="/contact">Contact</Nav.Link>

              {!isLogged && 
                <Nav.Link className={location.pathname === "/login" ? "navbar__link active" : "navbar__link"} href="/login">Se connecter</Nav.Link>   
              }
              {isLogged &&
                <NavDropdown className='navbar__link navbar__link__dropdown' title={'Bonjour ' + user.firstName} id="basic-nav-dropdown">
                  <NavDropdown.Item className='navbar__link__dropdown__link' href="/galeries">Mes galeries</NavDropdown.Item>
                  {!user.isClient && 
                    <NavDropdown.Item className='navbar__link__dropdown__link' href="/clients">Mes clients </NavDropdown.Item>
                  }
                  <NavDropdown.Item className='navbar__link__dropdown__link' href="/mon-compte/:id">Mon compte</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item className='navbar__link__dropdown__link' onClick={handleLogout}> Se déconnecter </NavDropdown.Item>
                </NavDropdown>
              }
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
  )};


