import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import {AdvancedImage} from '@cloudinary/react';
import {Cloudinary} from "@cloudinary/url-gen";


import Swal from 'sweetalert2'
import { formatDate } from '../../../utils/formatDate';
import './styles.scss';
import 'react-datepicker/dist/react-datepicker.css';
import AddGallery from '../../../containers/AddGallery';


export const ShootingList = ({
  isLogged,
  shootings,
  firstConnect,
  deleteShooting,
  fetchShootingsByUser,
  user,
}) => {
  
  useEffect(() => {
    if (isLogged && !user.isClient) {
      fetchShootingsByUser();
    }
  }, [
    fetchShootingsByUser,
    isLogged,
    user.isClient,
  ]);

  if(isLogged & user.firstConnect) {
    return <Navigate to="/temp" />;
  }

   // Create a Cloudinary instance and set your cloud name.
   const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.REACT_APP_CLN_CLOUD_NAME,
    }
  });

  const handleDeleteShooting = async (evt) => {
    evt.preventDefault();
    Swal.fire({
      title: 'Es tu sûre ?',
      text: "Ceci est irréversible!",
      icon: 'warning',
      iconColor: '#b58d34',
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonColor: '#b58d34',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprime!'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteShooting(evt.target.id);
        Swal.fire({
          title: 'Supprimé!',
          text: 'La galerie a été supprimée.',
          icon: 'success',
          confirmButtonColor: '#b58d34',
        })
      }
    })
  };

  

  return (
    < div>
      { firstConnect && ( <Navigate to={`/temp`} /> )}
      { !isLogged && <Navigate to={`/login`} /> }

      { isLogged &&
        <>
          <h1 className='galleries__title'> Mes galeries </h1>
          { shootings.length === 0 && user.isClient && 
            <>
              <p> Vous n'avez pas encore de galerie.</p>
              <p> Pas de panique, je m'occupe de tout et vous préviens dès que vos photos sont en ligne. </p>
            </>
          }
          { !user.isClient &&
            <AddGallery />
          }
          <div className="galleries">
            { shootings.sort((a, b) => {
              if (a.date < b.date)
                return 1;
              if (a.date > b.date)
                return -1;
              return 0;
            }).map((shooting) => {
              return (
                <a href={"/galerie/" + shooting.id} className="galleries__card" key={shooting.id}>
                  <div className='d-flex justify-content-between'>
                    <h4>{shooting.nameOfGallery}</h4> 
                    { !user.isClient && 
                      <div> 
                        <i id={shooting.id} onClick={handleDeleteShooting} className="bi bi-trash3"></i> 
                      </div>
                    }
                  </div>
                  { shooting.pictures && shooting.pictures.length > 0 ? 
                    <AdvancedImage
                      cldImg={cld.image(shooting.pictures[0].publicId)}
                      className='galleries__card__image'
                      alt={shooting.pictures[0].name}
                      id={shooting.id}
                    /> : 
                    <div className='galleries__card__image__placeholder'> <p className='galleries__card__image__placeholder__text'> Pas encore d'images </p> </div> 
                  }
                  <div className="card-body">
                    { !user.isClient && 
                      <h5 className="card-title galleries__card__body">
                        Séance photo pour {shooting.user.firstName} {shooting.user.lastName} le {formatDate(shooting.date)}
                      </h5> 
                    }
                    { user.isClient && 
                      <h5 className="card-title">
                        Séance photo du {formatDate(shooting.date)}
                      </h5> 
                    }
                    <p className="card-text">
                      {shooting.favouritesSelected ? 'favoristes choisies' : shooting.numberOfPictures + ' photos à choisir parmi ' + shooting.pictures.length + ' proposées' }
                    </p>
                  </div>
                </a>
              )}
            )}

          </div>
        </>
      }
    </div>
  );
};

ShootingList.propTypes = {
};
