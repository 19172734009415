import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2'

import './styles.scss';

export const AddPicture = ({
  uploadImage,
}) => {

    let {id} = useParams();

    const [showForm, setShowForm] = useState(false);
    const [imagesToUpload, setImagesToUpload] = useState([]);

    /* const onChange = (e) => {
      setFiles(e.target.files);
    } */

    /* var config = {
      onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
        setProgressBar(percentCompleted);
      }
    }; */
    
    /* const handleUploadOLD = (e) => {
      e.preventDefault();
      
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i]);
      }
    
      uploadImage(formData, id, config);
    }; */

    const handleUpload = async () => {
      try {
        for (let i = 0; i < imagesToUpload.length; i++) {
          await uploadImageWithProgress(i); // Attend que l'image soit uploadée avant de passer à la suivante
        }
        Swal.fire("Succès", "Toutes les images ont été téléchargées avec succès.", "success");
        setImagesToUpload([]); // Réinitialise après succès
      } catch (error) {
        Swal.fire("Erreur", "Une ou plusieurs images n'ont pas pu être téléchargées.", "error");
      }
    };
    
    
    
    const uploadImageWithProgress = async (index) => {
      const { file } = imagesToUpload[index];
      const formData = new FormData();
      formData.append('file', file);
    
      const config = {
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setImagesToUpload((prev) => {
            const updatedImages = [...prev];
            updatedImages[index] = {
              ...updatedImages[index],
              progress, // Mise à jour de la progression
            };
            return updatedImages;
          });
        },
      };
    
      await uploadImage(formData, id, config);
    };
    
    
    
    
     const toggleForm = () => {
      setShowForm(!showForm);
      };


      const selectImage = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.multiple = true;
        input.accept = 'image/*';
        input.click();
      
        input.addEventListener('change', (e) => {
          const files = e.target.files;
          const newImages = Array.from(files).map((file) => ({
            file, // Le fichier brut pour l'upload
            url: URL.createObjectURL(file), // L'URL blob pour l'aperçu
            progress: 0, // Progression initialisée à 0
          }));
      
          setImagesToUpload((prev) => [...prev, ...newImages]); // Ajoute à l'état existant
        });
      };
            


  return (
  
    <div className='addPicture'>
       <button onClick={toggleForm} className="addPicture__button">
          <i className="bi bi-plus" style={{ fontSize: "2rem" }}></i>
          <span className="button-text">Ajouter des photos</span>
        </button>

      {showForm && (
        <div className='addPicture__form-container'>
          <div 
            className="myButton d-block text-center" 
            onClick={selectImage}
          >
            Sélectionner une ou plusieurs images
          </div>
          <div className='addPicture__form-container__images'>
            <p className='mt-4'>Images sélectionnées :</p>
            {imagesToUpload.length > 0 && (
              <div className="upload-progress-overview">
                <p>
                  Téléchargement de l'image {imagesToUpload.findIndex(img => img.progress < 100) + 1} 
                  / {imagesToUpload.length} en cours...
                </p>
              </div>
            )}
            {imagesToUpload.map((image, index) => (
              <div className='d-flex align-items-center' key={index}>
                <img 
                  className='addPicture__form-container__images__item' 
                  src={image.url} 
                  alt='' 
                />
                <div className='progress w-100 ms-3'>
                  <div 
                    className='progress-bar progress-bar-striped progress-bar-animated' 
                    role='progressbar' 
                    aria-label='progressbar' 
                    aria-valuenow={image.progress} 
                    aria-valuemin="0" 
                    aria-valuemax="100" 
                    style={{ width: `${image.progress}%` }}
                  >
                    {image.progress} %
                  </div>
                </div>
                {image.progress === 100 && <span className="text-success ms-2">✔ Téléchargé</span>}
              </div>
            ))}
          </div>

          <button type='button' onClick={handleUpload}>Ajouter</button>
        </div>
      )}

      {/* {progressBar > 0 &&  progressBar < 100 &&
        <div className='progress'>
          <div className='progress-bar progress-bar-striped progress-bar-animated' role='progressbar' aria-label='progressbar' aria-valuenow={progressBar} aria-valuemin="0" aria-valuemax="100" style={{width: `${progressBar}%`}}> {progressBar} % </div>
        </div>
      } */}
    
    </div>
 
  );
};

AddPicture.propTypes = {
};