import {
  DELETE_PICTURE,
  UPLOAD_IMAGE,
  SET_FAVOURITE,
} from '../actions/picture';

import {
  fetchShooting,
} from '../actions/shooting';

import api from './utils/api';

const picturemiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case DELETE_PICTURE: {
      api({
        method: 'DELETE',
        url: `picture/${action.pictureId}`,
      })
        .then((response) => {
          action.shootingId && store.dispatch(fetchShooting(action.shootingId));
          //action.themeId && store.dispatch(FetchThemesByUser());
        })
        .catch((error) => {
            console.log(error)
          });
      break;
    }

    case UPLOAD_IMAGE: {
      console.log('UPLOAD_IMAGE')
      api({
        method: 'POST',
        url: `picture/shooting/${action.shootingId}/upload`,
        data: action.formData,
        timeout: 0,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        ...action.config,
      })
        .then((response) => {
          store.dispatch(fetchShooting(action.shootingId));
        })
        .catch((error) => {
            console.log(error)
          });
      break;
    }

    case SET_FAVOURITE: {
      api({
        method: 'PATCH',
        url: `picture/${action.pictureId}`,
        data: {
          isFavourite: !store.getState().shooting.shooting.pictures.find(picture => picture.id === Number(action.pictureId)).isFavourite,
        },
      })
        .then((response) => {
          window.location.reload(false);
      })
        .catch((error) => {
            console.log(error)
          });
      break;
    }

    default:
      next(action);


  }
};

export default picturemiddleware;
